import { type LanguageCode, supportedLanguageCodes } from '@/constants/languages';

export function detectLanguageCode(): LanguageCode {
  const { languages } = navigator;

  const zhVariants: Record<string, 'Hans' | 'Hant'> = {
    CN: 'Hans',
    SG: 'Hans',
    MY: 'Hans',
    TW: 'Hant',
    HK: 'Hant',
    MO: 'Hant',
  };

  for (const language of languages) {
    // eslint-disable-next-line prefer-const
    let [languageCode, region] = language.split('-');

    if (languageCode === 'zh') {
      languageCode = `zh-${zhVariants[region] || 'Hans'}`;
    }

    if (supportedLanguageCodes.includes(languageCode as LanguageCode)) {
      return languageCode as LanguageCode;
    }
  }

  return 'ja';
}
