import '@/i18n';
import '@/index.css';
import 'regenerator-runtime/runtime';

import React from 'react';
import ReactDOM from 'react-dom/client';

import App from '@/App';

const rootElement = document.getElementById('root');
if (rootElement) {
  ReactDOM.createRoot(rootElement).render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
  );
}
