import '@/i18n';
import '@/index.css';

import { lazy, Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { ReactQueryProvider } from '@/components/ReactQueryProvider';
import { useAutoChangeTheme } from '@/hooks/useTheme';
import NotFound from '@/pages/NotFound';

const GlobalToaster = lazy(() => import('@/components/GlobalToaster'));
const NavLayout = lazy(() => import('@/components/NavLayout'));
const PWABadge = lazy(() => import('@/components/PWABadge'));

const HomePage = lazy(() => import('@/pages/Home'));
const StatsPage = lazy(() => import('@/pages/Stats'));
const ChatPage = lazy(() => import('@/pages/Chat'));
const MePage = lazy(() => import('@/pages/Me'));

function App() {
  useAutoChangeTheme();

  return (
    <Suspense fallback={<div className="bg-base-200" />}>
      <ReactQueryProvider>
        <GlobalToaster />
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<NavLayout />}>
              <Route index element={<HomePage />} />
              <Route path="stats" element={<StatsPage />} />
              <Route path="chat" element={<ChatPage />} />
              <Route path="me" element={<MePage />} />
            </Route>
            <Route path="*" element={<NotFound />} />
          </Routes>
          <PWABadge />
        </BrowserRouter>
      </ReactQueryProvider>
    </Suspense>
  );
}

export default App;
